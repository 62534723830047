/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

ion-router-outlet {
  z-index: -1;
}
/* force portrait mode */
